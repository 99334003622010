export default {
  methods: {
    createMutation(mutation: string, variables = {}, loadingKey: string) {
      return new Promise(async (resolve, reject) => {
        this[loadingKey] = true;
        try {
          // @ts-ignore
          const data = await this.$apollo.mutate({
            mutation,
            variables
          });
          resolve(data);
        } catch (e) {
          // @ts-ignore
          this.$q.notify({
            message: e.message,
            multiLine: true,
            type: "negative"
          });
          reject(e);
        }
        this[loadingKey] = false;
      });
    }
  }
};
