
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import SkinsplusItem from "@/components/buy/SkinsplusItem.vue";

@Component({
  components: { SkinsplusItem, CircleLoading },
  apollo: {
    items: {
      query: gql`
        query($steamBotIds: [ID!]!) {
          getAllSteamBotItemsBySteamBotIds(steamBotIds: $steamBotIds)
        }
      `,
      update(data) {
        return data.getAllSteamBotItemsBySteamBotIds || [];
      },
      variables() {
        return {
          steamBotIds: this.steamBots.map(({ _id }) => _id)
        };
      },
      fetchPolicy: "no-cache"
    }
  }
})
export default class SteamBotItemsListDialog extends Vue {
  @PropSync("value", { type: Boolean }) show!: Boolean;
  @Prop() steamBots!: any[];
  @Prop() tradeLink!: string;
  private tradeUrl: string = "";
  private tradesCount: number = 10;
  private selectedItems: any[] = [];
  private items: any[] = [];
  private loading = false;
  private searchText = "";
  private minPrice: null | number = null;
  private maxPrice: null | number = null;

  get filteredAndSortedItems() {
    return (this.searchText
      ? this.items.filter(
          ({ name }) =>
            name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
        )
      : this.items
    ).sort((a, b) => {
      return b.price - a.price;
    });
  }

  get btnDisabled() {
    return (
      !/(https?:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9_-]+)/i.test(
        this.tradeUrl
      ) || !this.items.length
    );
  }

  created() {
    this.tradeUrl = this.tradeLink;
  }

  select(item: any) {
    const index = this.selectedItems.findIndex(({ id }) => item.id === id);

    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
  }

  selectAll() {
    this.selectedItems = (this.filteredAndSortedItems || this.items).filter((v) => {
      if(typeof this.minPrice === 'number' && v.price < this.minPrice){
        return false;
      }

      if(typeof this.maxPrice === 'number' && v.price > this.maxPrice){
        return false;
      }

      return true;
    }).slice();
  }

  async send() {
    let tradeCounts = this.tradesCount;
    let currentTradeUrl = this.tradeUrl.split(',')

    if(currentTradeUrl.length > 1  ){
      this.tradeUrl = currentTradeUrl[0];


      if(this.steamBots.length > tradeCounts * currentTradeUrl.length){
        this.$q.notify({
          message: 'Количество ботов превышает количество разрешенных трейдов',
          color: 'negative',
          position: 'bottom',
        });
        return;
      }
    }

    for await (let steamBot of this.steamBots) {
      const items = this.selectedItems.filter(
        item => item.steamBotId === steamBot._id
      );

      if (items.length || !this.selectedItems.length) {
        await this.sendAllSteamBotItems(steamBot._id, items);

        if(currentTradeUrl.length > 1){
          tradeCounts--;

          if(tradeCounts === 0){
            tradeCounts = this.tradesCount;
            currentTradeUrl = currentTradeUrl.slice(1);
            this.tradeUrl = currentTradeUrl[0];
          }
        }
      }
    }
  }

  async sendAllSteamBotItems(steamBotId: string, items: any[], attempts = 3) {
    this.loading = true;
    try {
      await this.$apollo.mutate({
        variables: {
          steamBotId,
          tradeUrl: this.tradeUrl,
          ...(items.length
            ? { steamBotItemIds: items.map(({ id }) => id) }
            : {})
        },
        mutation: gql`
          mutation sendAllSteamBotItems(
            $steamBotId: ID!
            $tradeUrl: String!
            $steamBotItemIds: [ID!]
          ) {
            sendAllSteamBotItems(
              steamBotId: $steamBotId
              tradeUrl: $tradeUrl
              steamBotItemIds: $steamBotItemIds
            )
          }
        `
      });
      this.$q.notify({
        message: `Успех`,
        multiLine: true,
        type: "positive"
      });
      this.show = false;
    } catch (e) {
      if(--attempts){
        return this.sendAllSteamBotItems(steamBotId, items, attempts);
      }

      this.$q.notify({
        message: e.message,
        multiLine: true,
        type: "negative"
      });
    }

    this.loading = false;
  }
}
