export function steamCurrencyByBalance(balance: string) {
  switch (true) {
    case balance.includes("₴"):
      return 18;
    case balance.includes("₸"):
      return 37;
    case balance.includes("$"):
      return 1;
    case balance.includes("€"):
      return 3;
    default:
      return 5;
  }
}

export function currencyBySteamBalance(balance: string) {
  switch (true) {
    case balance.includes("₴"):
      return "UAH";
    case balance.includes("₸"):
      return "KZT";
    case balance.includes("$"):
      return "USD";
    case balance.includes("€"):
      return "EUR";
    default:
      return "RUB";
  }
}
