export default {
  methods: {
    async recursionMutate(
      mutate: any,
      variables: any = {},
      done: any = () => {},
      attempts = 3,
      attempt = 0
    ) {
      if (attempts === attempt) {
        return null;
      }

      try {
        const data = await mutate({ variables });
        done(data);
        return data;
      } catch (e) {
        return await this.recursionMutate(
          mutate,
          variables,
          done,
          attempts,
          ++attempt
        );
      }
    }
  }
};
